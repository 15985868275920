'use client';

import { ProgressProvider } from '@bprogress/next/app';

export const ProgressProviders = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ProgressProvider
      // height="4px"
      // color="#fffd00"
      options={{ showSpinner: false }}
      delay={200}
      // shallowRouting
    >
      {children}
    </ProgressProvider>
  );
};
